import { Component, Input } from '@angular/core';

@Component({
  selector: 'troublemaker',
  templateUrl: './troublemaker.component.html',
  styleUrls: ['./troublemaker.component.scss']
})
export class TroublemakerComponent {
  @Input() size = '200px';
}
