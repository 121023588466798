import { Component, OnInit } from '@angular/core';
import { ElfsightEmbedSDK } from '@elfsight/embed-sdk';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    ElfsightEmbedSDK.displayWidget(
      document.querySelector('.elfsight-app-078e41a2-e443-4d53-be3c-d772600741a4'),
      '078e41a2-e443-4d53-be3c-d772600741a4'
    );
  }
}
