import { Component, OnInit } from '@angular/core';
import $ from 'jquery';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
  ngOnInit(): void {
    $( ".text-slide" ).hide();
    $( "h3" ).on( "click", function(event) {
      $(event.target).next('.text-slide').slideToggle( "20000000", function() {
      });
    });
    $( ".bi-caret-down-fill" ).on( "click", function(event) {
      $(event.target).parent().next('.text-slide').slideToggle( "20000000", function() {
      });
    });
  }
}
