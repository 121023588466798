export const docList: {
  title: string,
  id: string,
  lead: string
}[] = [
  {
    title: 'Redaktionsteam: inhaltliche Vorbereitung - Thema Rassismus',
    id: '2PACX-1vRlKJQZWcKv_N535Sh5RqnAuwLeWMXtBfzynkZNRKdTFEZ860yClCl0YHNez1L5GeVo8Vikx3_-2JXN',
    lead: 'isa',
  },
  {
    title: 'Schnitzeljagd',
    id: '2PACX-1vR4jrb6jsqZBhMQZ21MFVU1J34WAFRXnF0ZTLwCiW4h7JHgX6RRgpGSQXUv34EocCVYjmWMymH5mc4t',
    lead: 'daniel',
  },
  {
    title: 'Kneipentour',
    id: '2PACX-1vSixpO2BIA_nIb3pr4gwLmCu2tvdJgY6Hx1PZN5cqiv__CV46Shm6dg7Z7zmbpkcLIHZ4hVvtnIWE23',
    lead: 'paulina',
  },
];
