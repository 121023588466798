<header class="red-bg">
  <img src="assets/images/SPD_KA2024_logo_white.png" alt="SPD-Logo mit Slogan: zuverlässig für unser Karlsruhe">
  <nav class="navbar navbar-expand-lg navbar-dark nav-fill w-100">
    <a routerLink="/aktuelles" role="button" routerLinkActive="active">Aktuelles</a>
    <a routerLink="/unser-programm" role="button" routerLinkActive="active">Unser Programm</a>
    <a routerLink="/unsere-liste" role="button" routerLinkActive="active">Unsere Liste</a>
  </nav>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<footer class="red-bg">
  <div class="content">
    &copy; {{ year }} <a href="https://www.spd-karlsruhe.de/impressum/" target="_blank" role="button" routerLinkActive="active">Impressum</a>
  </div>
</footer>
