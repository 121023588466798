import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ExampleComponent } from "./components/example/example.component";
import { NewsComponent } from "./components/news/news.component";
import { ListComponent } from "./components/list/list.component";
import { ProgramComponent } from "./components/program/program.component";
import { JobsComponent } from "./components/jobs/jobs.component";
import { SJSolutionComponent } from "./components/sjsolution/sjsolution.component";
import { SJVideoComponent } from "./components/sjvideo/sjvideo.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/unsere-liste',
    pathMatch: 'full',
  },
  {
    path: 'aktuelles',
    component: NewsComponent,
    pathMatch: 'full',
    data: {
      title: 'Beispiel'
    }
  },
  /*{
    path: 'example',
    component: ExampleComponent,
    pathMatch: 'full',
    data: {
      title: 'Beispiel'
    }
  },*/
  {
    path: 'unsere-liste',
    component: ListComponent,
    pathMatch: 'full',
    data: {
      title: 'Unsere Liste'
    }
  },
  {
    path: 'unser-programm',
    component: ProgramComponent,
    pathMatch: 'full',
    data: {
      title: 'Unsere Liste'
    }
  },
  {
    path: 'mitmachen',
    component: JobsComponent,
    pathMatch: 'full',
    data: {
      title: 'Mitmachen'
    }
  },
  {
    path: 'schnitzeljagd',
    component: SJSolutionComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd'
    }
  },
  {
    path: 'cJLdooMiIHOQxpwy',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: '-y6ueSkxcgc',
      name: 'Wort 1 - Haus der Fraktionen'
    }
  },
  {
    path: 'NWerhTySwZeRTlxQ',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'ymh1yxMhly8',
      name: 'Wort 2 - Marktplatz'
    }
  },
  {
    path: 'tmkzDabLRnVswkOW',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'fmfOjMQstSw',
      name: 'Wort 3 - Waldstraße'
    }
  },
  {
    path: 'cJRAjGAwAlFqPcsf',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'koZt6Pv4Lyk',
      name: 'Wort 4 - Platz der Grundrechte'
    }
  },
  {
    path: 'UeSYvTDKawMXNnFo',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'QztuGGZ2EdE',
      name: 'Wort 5 - Naturkundemuseum'
    }
  },
  {
    path: 'AcewgWPfvQeUQkbF',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'LQehftABQ8c',
      name: 'Wort 6 - Ludwigsplatz'
    }
  },
  {
    path: 'bSNVPGxlATKcgYYw',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'KOqgVh4a7F8',
      name: 'Wort 7 - Bundesverfassungsgericht'
    }
  },
  {
    path: 'zHKzCTVLgUtvvnlK',
    component: SJVideoComponent,
    pathMatch: 'full',
    data: {
      title: 'Schnitzeljagd',
      yt_tag: 'nzxzSHzTp2A',
      name: 'Wort 8 - Kirchplatz St. Stephan'
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
