import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ExampleComponent } from './components/example/example.component';
import { JobsComponent } from './components/jobs/jobs.component';
import { ListComponent } from './components/list/list.component';
import { NewsComponent } from './components/news/news.component';
import { ProgramComponent } from './components/program/program.component';
import { SJSolutionComponent } from "./components/sjsolution/sjsolution.component";
import { SJVideoComponent } from "./components/sjvideo/sjvideo.component";
import { TroublemakerComponent } from './components/troublemaker/troublemaker.component';

@NgModule({
  declarations: [
    AppComponent,
    ExampleComponent,
    JobsComponent,
    ListComponent,
    NewsComponent,
    ProgramComponent,
    SJSolutionComponent,
    SJVideoComponent,
    TroublemakerComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
