<h1>
  Kommunalwahlprogramm der SPD-Karlsruhe 2024-2029<br>
  <span class="light">Die ganze Stadt im Blick</span>
</h1>
<h2>1. Präambel: Die ganze Stadt im Blick!</h2>
<p>
  Wir stehen für eine offene und solidarische Gesellschaft; unabhängig von Herkunft, Religion, Weltanschauung, Behinderung, Alter oder sexueller und geschlechtlicher Identität. Dafür arbeiten wir. In der Überzeugung, dass unsere Stadt noch lebenswerter gestaltet werden kann, und mit dem Willen, dieses Ziel kommunalpolitisch zu erreichen, erklären wir: Die SPD Karlsruhe steht zu ihrer Verantwortung für die Entwicklung und die soziale Gestaltung unserer Stadt, um die Lebensverhältnisse für die hier lebenden Menschen zu verbessern. Wir haben dabei nicht Einzelinteressen, sondern die ganze Stadt im Blick!
</p>
<p>
  Dazu gehören bezahlbare Mieten, ein starkes Betreuungsangebot für die Kleinsten, Familienfreundlichkeit in allen Bereichen, eine leistungsfähige öffentliche Infrastruktur, lebenswerte Stadtteile, gute Arbeit in leistungsfähigen Unternehmen, eine kreative Kulturpolitik, ein verantwortungsbewusstes Verhältnis zu unserer Umwelt, solidarische Antworten für eine älter werdende Gesellschaft und die Stärkung des Zusammenhalts in unserer Stadt.
</p>
<p>
  Unsere Kommunalpolitik orientiert sich an unverändert gültigen Werten, dem Gebot der Gerechtigkeit, der Sicherung der Chancengleichheit und der Pflicht, solidarisch füreinander einzustehen. Die Karlsruher SPD will zusammen mit den Bürger:innen die Stadtpolitik aktiv mitgestalten. Wir treten für Bürger:innennähe und Transparenz politischen Handelns ein. Voraussetzung dafür ist ein intensiver Dialog mit allen Bürger:innen.
</p>
<p>
  Wir stehen für eine Politik, die zuhört und zusammenführt. Wir stehen für eine umfassende Teilhabe und verstärkte Beteiligung aller Bürger:innen. Unser Ziel ist eine bürger:innennahe und moderne Verwaltung, die den Menschen als Partner zur Seite steht: vom Oberbürgermeister bis zu den Ortsverwaltungen und den aktiv einbezogenen Bürgervereinen.<br>
  Die Interessen der arbeitenden Bevölkerung in unserer Stadt sind für uns von großer Bedeutung. Betriebsbedingte Kündigungen bei der Stadt Karlsruhe und den städtischen Gesellschaften bleiben für uns ausgeschlossen.
</p>
<p>
  Die SPD Karlsruhe macht Politik für die Beschäftigten, für Wirtschaft, Mittelstand und Handwerk, weil wir darin den Schlüssel für den Wohlstand aller sehen. Wir stehen für solide Finanzen, eine starke Wirtschaft und eine moderne Infrastruktur.
</p>
<p>
  Die SPD Karlsruhe unterstützt die Kreativkraft der Wissenschaft in unserer Region. Wir fördern aktiv Innovationsprozesse in Forschung und Entwicklung. Der Vorsprung, den Wissenschaftler:innen in Karlsruhe durch ihre Forschungsleistung erzielen, spielt im heutigen globalen Wettbewerb eine entscheidende Rolle. Daher bekennen wir uns zu einer starken Technologieregion und einem starken Digitalstandort Karlsruhe.
</p>
<p>
  Die SPD Karlsruhe richtet ihren Blick über die Stadtgrenzen hinaus, um in einem solidarischen Miteinander mit den benachbarten Gemeinden die gesamte Region zu stärken und damit auch dem Wohl der Stadt Karlsruhe zu dienen.
</p>
<p>
  Die SPD Karlsruhe tritt für eine ökologische und nachhaltige Politik ein, weil das die Voraussetzung für ein besseres Leben ist. Wir arbeiten aktiv daran mit, dass Karlsruhe bis 2040 klimaneutral wird. Gleichzeitig wollen wir Klimaanpassungsmaßnahmen auf den Weg bringen, um die heute schon bestehenden Folgen des Klimawandels abzumildern.
</p>
<p>
  Die SPD Karlsruhe fasst ihre politischen Forderungen in den vorliegenden Abschnitten zusammen. Sie bilden die Basis einer Politik, die unsere Stadt voranbringt und auch in Zeiten globaler Umbrüche lebenswert erhält.
</p>

<h2>2. Die Stadt der Bildung und Betreuung</h2>
<h3>Frühkindliche Förderung <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    In den vergangenen Jahren wurden zahlreiche Kindertagesstätten neu errichtet und das Angebot an Betreuungsplätzen in Karlsruhe deutlich erweitert. Dennoch übersteigt die Nachfrage der Eltern nach wie vor das vorhandene Angebot an Plätzen. Aus diesem Grund fordert die SPD die Einführung eines Maßnahmenpakets zur Kinderbetreuung in unserer Stadt. Die Gewährleistung von Zuverlässigkeit in der frühkindlichen Bildung steht dabei im Mittelpunkt.
  </p>
  <p>
    Die Gewinnung und Bindung von Fachkräften, die Unterstützung der Träger sowie die Sicherstellung von wohnortnahen und ausreichenden Angeboten stellen eine große Herausforderung dar. Die vielfältige Trägerlandschaft wird von uns als Stärke angesehen. Um sicherzustellen, dass sie auch in Zukunft Bestand hat, ist eine angemessene Finanzierung erforderlich, die auch die gestiegenen Baukosten und Mieten berücksichtigt.
  </p>
  <p>
    Es liegt in der Verantwortung der Stadt, gute Rahmenbedingungen zu schaffen, indem die personelle Ausstattung den aktuellen Herausforderungen gerecht wird. Verwaltungskräfte sollen die Einrichtungen unterstützen und hauswirtschaftliche Kräfte sollen Erzieher:innen bei nicht-pädagogischen Aufgaben entlasten. Eine angemessene Bezahlung des Fachpersonals ist unabdingbar. Wir setzen uns konsequent für unbefristete Stellen ein. Die Umsetzung einer erfolgreichen Inklusion erfordert zusätzliche Fachkräfte, für die entsprechende Fortbildungen und Nachqualifizierungen ermöglicht werden müssen.
  </p>
  <p>
    Die Förderung der sprachlichen Entwicklung hat für die SPD einen hohen Stellenwert. Daher sind dauerhaft und verlässlich Sprachförderkräfte in jeder Einrichtung notwendig. Die praxisintegrierte Ausbildung von Erzieher:innen soll bedarfsgerecht erweitert werden.
  </p>
  <p>
    Eltern müssen bei der Suche nach Betreuungsplätzen besser unterstützt werden, etwa durch ein gut funktionierendes Kita-Portal.
  </p>
  <p>
    Um dem anhaltend hohen Bedarf gerecht zu werden, müssen weiterhin neue Gebäude errichtet werden. Wir sehen auch Potenzial in Betriebs-Kitas, um mehr Plätze anzubieten.
  </p>
  <p>
    Die Einrichtung von Familienzentren stärkt Eltern und Kinder gleichermaßen. Der flächendeckende Ausbau dieser Zentren ist unser Ziel, damit sie eine effektive Unterstützungsarbeit in den Quartieren leisten können.
  </p>
  <p>
    Die SPD strebt kostenlose Kindertageseinrichtungen an. Da sie Orte der Bildung und Betreuung sind, sollen sie für alle zugänglich sein. Die Umsetzung dieser Idee liegt in der Verantwortung des Landes Baden-Württemberg. Die Stadt unterstützt die Plätze durch einen hohen Erstkinderzuschuss, und auch in Zukunft werden Geschwisterkinder durch die Karlsruher Geschwisterkindregelung kostenfreie Plätze erhalten. Des Weiteren haben wir Beitragsreduzierungen für Eltern mit niedrigem Einkommen beschlossen. Wir müssen bürokratische Hürden bei der Beantragung von Unterstützungen abbauen, um sicherzustellen, dass diese bei den betroffenen Familien ankommen.
  </p>
  <p>
    Es ist unser Ziel, sicherzustellen, dass die Kitagebühren verschiedener Träger nicht stark voneinander abweichen. Die frühkindliche Förderung soll nicht vom Geldbeutel der Eltern abhängen. Wir lehnen es ab, dass Träger überhöhte Beiträge von Familien verlangen. Die frühkindliche Förderung eröffnet Chancen für alle Kinder, deshalb setzen wir uns für gleiche Zugangsmöglichkeiten ein.
  </p>
  <p>
    Auch Tageseltern leisten einen wichtigen Beitrag zur frühkindlichen Bildung und verdienen unsere Unterstützung bei der Erfüllung ihrer bedeutenden Aufgabe durch optimale Bedingungen, wie beispielsweise eine bessere Vergütung und die Unterstützung bei der Suche nach finanzierbaren Räumlichkeiten.
  </p>
</div>

<h3>Unterstützung für Kinder und Jugendliche <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die Frühen Hilfen bilden einen unverzichtbaren Bestandteil des sozialen Netzwerks unserer Stadt. Neben zahlreichen Elterncafés und Unterstützungsangeboten der Jugendhilfe tragen auch sie zur frühzeitigen Prävention bei.
  </p>
  <p>
    Die SPD betrachtet die Ermöglichung von Teilhabe für alle als zentrales Anliegen. Um der Kinderarmut entgegenzuwirken und den gesellschaftlichen Zusammenhalt sowie demokratische Einstellungen zu fördern, sind qualitativ hochwertige Angebote erforderlich. Der Stadtjugendausschuss leistet hervorragende Arbeit für und mit jungen Menschen. Mit dem von uns beantragten Ausbau der Jugendarbeit in den Karlsruher Höhenstadtteilen ist bereits ein flächendeckendes Angebot vorhanden. Dieses soll durch gezielte Investitionen gesichert werden, um einem möglichen Sanierungsstau vorzubeugen. Unser Einsatz gilt einem neuen Gebäude für das Kinder- und Jugendzentrum in der Südstadt. Zusätzlich benötigen wir in den Ferien ausreichende und bezahlbare Angebote, die inklusiv für alle Kinder zugänglich sind.
  </p>
  <p>
    Unsere Spielplätze müssen den veränderten Anforderungen entsprechen und durch schattenspendende Elemente auch an heißen Tagen einen angenehmen Aufenthalt ermöglichen.
  </p>
  <p>
    Wir möchten jungen Menschen, die als &quot;Systemsprenger:innen&quot; bezeichnet werden, durch gezielte Hilfen, wie therapeutische Wohngruppen, Intensivgruppen und individualpädagogische Maßnahmen, Unterstützung bieten.
  </p>
  <p>
    Wir setzen uns dafür ein, bestehende Maßnahmen für einen erfolgreichen Übergang aller jungen Menschen von der Schule in die Ausbildung und den Beruf auszubauen. Insbesondere Personen mit speziellem Hilfebedarf sollen gezielt unterstützt werden, und für Personen mit erschwerten Zugängen sollen passgenaue Hilfen bereitgestellt werden.
  </p>
</div>

<h3>Schulen im Fokus <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Angesichts knapperer Ressourcen für Investitionen liegt unsere Priorität bei der Sanierung städtischer Gebäude auf Schulen. Notwendige Instandhaltungen und Sanierungsmaßnahmen dürfen nicht aufgeschoben werden. Ausreichende Räumlichkeiten sind auch für qualitativ hochwertige Betreuungsangebote nach dem Unterricht unerlässlich. Die erfolgreiche Umsetzung des Rechtsanspruchs auf Ganztagsbetreuung für Grundschulkinder ist von großer Bedeutung. Ein gutes Schulessen soll für alle Kinder in unserer Stadt selbstverständlich sein. Wir setzen uns dafür ein, dass das Schulessen regional produziert und von hoher Qualität ist.
  </p>
  <p>
    Wir werden uns weiterhin für die Digitalisierung an allen Schulen einsetzen. Dazu zählen unter anderem die flächendeckende Versorgung mit WLAN, digitale Präsentationsmöglichkeiten und die Unterstützung beim IT-Support durch Fachkräfte an allen Schulen. Den Schüler:innen sollen kostenlose, digitale Endgeräte zur Verfügung gestellt werden.
  </p>
  <p>
    Nachdem es uns in den vergangenen Jahren gelungen ist, die Schulsozialarbeit flächendeckend auszubauen, fordern wir nun eine Ausweitung dieses Erfolgsmodells, um allen Beteiligten am Schulleben durch gezielte Unterstützung entgegenzukommen.
  </p>
  <p>
    Die Sauberkeit in den Schulen soll durch die Einführung eines Qualitätsmanagements sowie durch die Aufstockung des eigenen Reinigungspersonals verbessert werden. Entsprechend unserer Forderung nach kostenfreier Bildung setzen wir uns weiterhin dafür ein, die Schulgelder an Fachschulen abzuschaffen.
  </p>
  <p>
    Wir wollen von den schulischen Reformen in Hamburg lernen und sie auf kommunaler Ebene umsetzen. Dies umfasst kostenlose Nachhilfe, Mensen mit gesunden Mahlzeiten, gezielte Sprachförderung, regelmäßige Qualitätssicherung und Transparenz bei Unterrichtsausfall. Ziel ist eine bessere Unterstützung für sozial benachteiligte Schüler:innen. Die inklusiven Schulen benötigen eine bessere Personalausstattung. Generell streben wir eine Verbesserung der Bildungsqualität an.
  </p>
</div>

<h2>3. Die Stadt der modernen Gesellschaft</h2>
<h3>Vielfältige Teilhabe <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unser Ziel ist die Förderung der gleichberechtigten Teilhabe aller Bürger:innen. Die Inklusion muss in sämtlichen kommunalpolitischen Bereichen Berücksichtigung finden. Wir unterstützen den Beirat für Menschen mit Behinderungen in seiner essenziellen Arbeit und begleiten die Umsetzung des Rechts auf Teilhabe in allen Lebensbereichen. Dies erfordert eine Ausweitung verschiedener Angebote. Wir wollen Zugänge durch einfache Sprache erleichtern und somit eine größere Anzahl von Menschen erreichen. Uns liegt eine umfassende Barrierefreiheit in unserer Stadt am Herzen.
  </p>
  <p>
    Die Förderung der Integration steht ebenfalls im Fokus unserer Bemühungen. Zahlreiche Vereine leisten hierzu einen bedeutsamen Beitrag. Auch die sozialen Träger unterstützen wir in ihrer integrativen Arbeit. Eine ausreichende Finanzierung ist hierbei von zentraler Bedeutung, für die wir uns vehement einsetzen. Als SPD beabsichtigen wir die Einrichtung eines Willkommenshauses. Hier sollen Integrationsangebote besser gebündelt werden, damit insbesondere Neuzugwanderte schnell notwendige Informationen erhalten und systematisch in im Prozess ihres Ankommens unterstützt werden.
  </p>
  <p>
    Eine erfolgreiche Integration erfordert außerdem, dass Sprachkurse die individuellen Lebenssituationen der Menschen besser berücksichtigen. Wir setzen uns insbesondere für Sprachkurse mit Kinderbetreuung ein, damit auch Eltern die deutsche Sprache schnell lernen können. Angesichts der veränderten Situation aufgrund des Kriegs in der Ukraine unterstützen wir weiterhin die dezentrale Bereitstellung von Wohnraum. Die Unterbringung in Hallen kommt für uns nicht in Frage. Wir setzen uns dafür ein, ausreichende Weiterbildungsangebote im Bereich Antirassismus und Antidiskriminierung für städtische Mitarbeiter:innen bereitzustellen. Antisemitismus sowie weiteren Formen gruppenbezogener Menschenfeindlichkeit müssen wir in unserer weltoffenen Stadt entschieden entgegentreten. Angebote wie die Wochen gegen Rassismus sowie finanzielle Mittel zur Umsetzung des Integrationsplans müssen auch in finanziell schwierigen Zeiten bedarfsgerecht zur Verfügung stehen. Wir setzen uns für niedrigschwellige Angebote im Alltag ein, wie beispielsweise leicht verständliche Anzeigen in der Straßenbahn.
  </p>
  <p>
    Queere Anliegen nehmen wir ernst. Die Stadt soll sich ebenfalls hauptamtlich mit queer-relevanten Themen befassen, um Vielfalt zu stärken. Das queere Jugendzentrum La Vie ist unverzichtbar. Wir teilen das Ziel eines Treffpunkts für Erwachsene. Veranstaltungen wie der Christopher Street Day müssen von der Stadt geschützt und unterstützt werden. Die dabei formulierten Forderungen nehmen wir ernst und unterstützen die Aktiven in ihrer Umsetzung.
  </p>
  <p>
    Gewalt, insbesondere häusliche Gewalt, muss auf allen staatlichen Ebenen entschieden bekämpft werden. Als Stadt Karlsruhe tragen wir unseren Teil dazu bei. Für die SPD ist dies eine Verpflichtung und keine Frage der Freiwilligkeit. Hierbei müssen Angebote für sämtliche Aspekte der UN-Konvention zum Schutz der Frauen geschaffen werden.
  </p>
  <p>
    Wir setzen uns ebenfalls in Karlsruhe für das Nordische Modell und gegen Sexkauf ein. Dazu streben wir die Ausweitung von Sperrgebieten an und initiieren eine gesellschaftliche Debatte über die Auswirkungen der Prostitution in unserer Stadt. Als Stadt wollen wir wirksame Ausstiegskonzepte fördern.
  </p>
  <p>
    Unsere Vorstellung von einer modernen Drogenpolitik beinhaltet Angebote wie den Drogenkonsumraum oder das Ahoch3 für Menschen mit Alkoholsucht. Diese Angebote sollen bedarfsgerecht erweitert werden und die Öffnungszeiten müssen der Lebensrealität entsprechen. Die kommunale Begleitung der Legalisierung von Cannabis erfolgt durch präventive Angebote zur Stärkung des Jugendschutzes. Wir unterstützen die Einführung von Drug-Checks.
  </p>
</div>

<h3>Armut verlangt Einsatz <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unsere sozialdemokratische Kommunalpolitik verfolgt entschlossen das Ziel, Armut auch auf lokaler Ebene zu bekämpfen. Dabei legen wir besonderen Wert auf die Stärkung der Karlsruher Pässe sowie auf die kontinuierliche Erweiterung der Sozialregion. Die Einkommensgrenze für den Bezug des Karlsruher Passes wollen wir weiter anheben, denn grade in den unteren Einkommensklassen war der relative Reallohnverlust der letzten Jahre besonders hoch. Des Weiteren ist es von großer Bedeutung, die Angebote der Tafeln durch bedarfsgerechte Fördermaßnahmen - entsprechend der im Vergleich zur Vergangenheit sehr viel höheren Anzahl an Personen, die die Hilfe der Tafeln benötigen - zu erweitern. Die Schuldnerberatung stellt einen essenziellen Baustein dar, doch gleichzeitig gewinnt auch die Energieberatung zunehmend an Relevanz. In diesem Bereich sollen ebenfalls digitale Angebote verstärkt ausgebaut werden.
  </p>
  <p>
    Um die Wohnraumversorgung nicht nur von Wohnungslosigkeit bedrohten Menschen, sondern auch von jenen, die Schwierigkeiten bei der Wohnraumsuche haben, zu gewährleisten, setzen wir auf das bewährte Konzept der kommunalen Wohnraumakquise.
  </p>
  <p>
    Langzeitarbeitslose Menschen können dank des von uns initiierten Programms „Sozialer Arbeitsmarkt“ wieder einer sinnstiftenden Arbeit nachgehen. Dieses ist unverzichtbar für die soziale Stadt. Unser Ziel ist es, durch solche Maßnahmen Arbeitslosigkeit zu bekämpfen und gleichzeitig individuelle Fähigkeiten zu fördern, um nachhaltige Integration in den regulären Arbeitsmarkt zu ermöglichen.
  </p>
</div>

<h3>Unterstützung im Alter <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Im Bereich der Pflege erkennen wir sowohl im ambulanten als auch im stationären Sektor einen großen Bedarf. Wir setzen uns dafür ein, die Angebote für ältere Menschen zu erweitern. Dies schließt auch ausreichende Kurzzeitpflegeplätze ein. Pflegenden Angehörigen möchten wir vermehrt kommunalen Pflegestützpunkte sowie Beratung zur Verfügung stellen. Hierfür müssen die Personalkapazitäten erhöht werden. Begründung: Da es die Pflegestützpunkte bereits gibt, soll man diese besser ausstatten, um das volle Potenzial zu entfalten. Außerdem werden durch eine Erhöhung der Personalkapazitäten höchstwahrscheinlich neue Ausbildungsplätze geschaffen.
  </p>
  <p>
    Die Erkenntnisse aus unserem initiierten Modellprojekt &quot;Innovative Pflege&quot; sollen dazu beitragen, eine verbesserte Pflege und Betreuung zu gewährleisten. Dafür setzen wir auf quartiersbasierte Angebote, die eine aktive Teilhabe im Alter ermöglichen. Ein zentraler Pflegestützpunkt soll allen Betroffenen Unterstützung bieten können. Der Stadtseniorenrat stellt ein wichtiges Sprachrohr für die Anliegen der älteren Generation dar und genießt die Unterstützung der SPD.
  </p>
</div>

<h3>Ehrenamt <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die zahlreichen Vereine und Institutionen in Karlsruhe leisten einen wesentlichen Beitrag zum sozialen Zusammenhalt unserer Stadtgesellschaft.
  </p>
  <p>
    Als SPD Karlsruhe setzen wir uns dafür ein, das Ehrenamt zu stärken und zu unterstützen. Uns liegt besonders am Herzen, Räume wie Stadtteilzentren zu schaffen und zu erhalten, die den Vereinen eine Heimat bieten und optimale Bedingungen für ihre Aktivitäten ermöglichen. Wir möchten sicherstellen, dass die Stadtverwaltung den Verantwortlichen mit unterstützender Hand bei den bürokratischen Hürden zur Seite steht, mit denen moderne Vereinsarbeit oft konfrontiert ist. Dadurch wollen wir dem Mangel an neuen Vereinsvorständen professionell entgegenwirken.
  </p>
  <p>
    Insbesondere die politische Jugendarbeit soll gestärkt werden, indem den demokratischen politischen Jugendverbänden Räumlichkeiten zugeteilt werden, die sie nutzen können.
  </p>
  <p>
    Für viele Vereine bildet die Ausrichtung von Festen die finanzielle Grundlage. Allerdings schrecken zahlreiche Auflagen viele Bürger:innen ab, solche Verantwortung zu übernehmen. Dadurch geht die Anzahl der Stadtteilfeste in Karlsruhe nach und nach zurück. Deshalb muss eine Überprüfung der behördlichen Genehmigungsverfahren erfolgen, um sie zu vereinfachen. Wir plädieren für eine starke Förderung von Vereinsfesten.
  </p>
  <p>
    Die SPD Karlsruhe versteht sich ebenfalls als zuverlässiger Partner der Rettungsorganisationen in der Stadt. In Bezug auf die Freiwilligen Feuerwehren stehen dringend notwendige Sanierungsmaßnahmen in den Feuerwehrhäusern an. Wir werden uns für pragmatische Lösungen einsetzen und nicht an den Freiwilligen Feuerwehren in Karlsruhe sparen.
  </p>
  <p>
    In den vergangenen Jahren wurden beträchtliche Mittel für die Bereitstellung moderner und professioneller Ausrüstung aufgebracht. Diesen Weg möchten wir konsequent fortsetzen. Wir setzen uns dafür ein, dass die Stadtverwaltung ehrenamtliches Engagement honoriert, beispielsweise durch vergünstigte Eintrittspreise für Kultur- und Sportveranstaltungen. Durch eine Schwächung des Ehrenamts und der Vereinsarbeiten entstehen gesellschaftliche Lücken, die von populistischen Kräften gefüllt werden können.
  </p>
</div>

<h3>Demokratie in den Stadtteilen <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die SPD Karlsruhe legt großen Wert auf die aktive Teilnahme der Bürger:innen an politischen Entscheidungen. Wir setzen uns nachdrücklich dafür ein, die Kooperation zwischen Bürgervereinen und der Stadtverwaltung weiter zu vertiefen.
  </p>
  <p>
    Zudem unterstützen wir alternative Formen der Bürger:innenbeteiligung, insbesondere von Jugendlichen und Kindern. Das bewährte Modell der jährlichen Jugendkonferenz wollen wir beibehalten. Darüber hinaus streben wir danach, die Stadteilkonferenzen und andere Verfahren der Bürger:innenbeteiligung auszubauen, um eine breite und umfassende Teilhabe zu ermöglichen. Die Einführung von Bürger:innenräten, in denen die Bürger:innen repräsentativ vertreten sind und ein Vorschlagsrecht gegenüber dem Gemeinderat besitzen, soll geprüft und versuchsweise durchgeführt werden.
  </p>
  <p>
    Die Ortsverwaltungen und Ortschaftsräte erfüllen auch viele Jahrzehnte nach der Eingemeindung eine essenzielle Funktion. Die Existenz der Ortsverwaltungen und des Stadtamts Durlach stehen für die SPD nicht zur Debatte. Wir beabsichtigen, die dezentrale Verwaltung zu stärken und widerstandsfähiger gegenüber den zunehmenden Herausforderungen moderner Kommunalverwaltung zu machen. Hierfür ist eine Anpassung der personellen Ausstattung an die aktuellen Erfordernisse notwendig.
  </p>
</div>

<h3>Digitale Verwaltung nah am Menschen <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Der Besuch der Bürgerbüros in Karlsruhe stellt für viele Bürger:innen unserer Stadt den direktesten Kontakt zur Stadtverwaltung dar. Ein optimaler Service mit kurzen Wartezeiten und reibungsloser Fallbearbeitung ist daher die beste Visitenkarte der kommunalen Verwaltung.
  </p>
  <p>
    Für die SPD Karlsruhe zählt der digitale Bürger:innenservice zu den zentralen Bestandteilen einer modernen Verwaltung, die nah am Menschen ist. Die bisherigen Entwicklungen der vergangenen Jahre gehen uns nicht weit genug. Daher werden wir uns entschieden für eine zügige Digitalisierung der behördlichen Abläufe einsetzen. Als IT-Stadt ist es unser Anspruch, auch die Verwaltung umfassend zu digitalisieren. Bürger:innendienste, die Ausländer:innenbehörde und zahlreiche weitere Ämter müssen in diesen Prozess konsequent einbezogen werden. Dabei dürfen keine neuen Barrieren für Bürger:innen aufgebaut werden. Deshalb muss es weiterhin auch Bürger:innendienste vor Ort geben.
  </p>
  <p>
    Zusätzlich möchten wir sicherstellen, dass die Beschäftigten der Stadtverwaltung gut auf die Veränderungen in der Arbeitswelt vorbereitet sind, die sich aus dem Einsatz von generativer Künstlicher Intelligenz (KI) ergeben. Hierfür setzen wir uns für die Einführung eines Leitfadens für die Stadtverwaltung ein, der den Einsatz von KI unter den Aspekten Sorgfaltspflichten, Ethik und Transparenz regelt.
  </p>
  <p>
    Wir sind uns bewusst, dass der digitale Wandel auch in der Stadtverwaltung eine zunehmend größere Rolle spielen wird. Uns ist es wichtig, dass unsere Mitarbeiter:innen durch gezielte Fortbildungen optimal auf eine veränderte und zunehmend digitalisierte Arbeitsumgebung vorbereitet sind. Gleichzeitig streben wir an, ihnen mehr Mitwirkungsmöglichkeiten zu bieten und Hierarchien flacher zu gestalten. Der Gesamtpersonalrat soll ebenfalls stärker in die Aufgabenbewältigung und -kritik einbezogen werden. Die Mitarbeitenden verdienen Wertschätzung und entsprechende Möglichkeiten der Weiterentwicklung nach einem gelebten Personalentwicklungskonzept. Nur so kann die Qualität in den Ämtern und damit auch eine gute Dienstleistung für die Bürger:innen der Stadt erhalten werden.
  </p>
</div>

<h2>4. Die Stadt der lebendigen Quartiere</h2>
<p>
  Karlsruhe erlebte lange Zeit ein Wachstum der Einwohnerzahl. Seit 2021 zeigt sich jedoch ein rückläufiger Trend. Auch für Unternehmen gibt es begrenzte Möglichkeiten, sich anzusiedeln. Die vielfältigen sozialen, ökologischen und wirtschaftlichen Herausforderungen werden uns in den kommenden Jahren intensiv beschäftigen. Bezahlbarer Wohnraum ist knapp, die Temperaturen steigen jährlich spürbar an und aufgrund unserer natürlichen Grenzen können wir kaum zusätzliche Flächen für Wohnungsbau oder andere Projekte erschließen. Daher ist es notwendig, die vorhandenen Ressourcen klug einzusetzen und etablierte Konzepte zu überdenken.
</p>
<p>
  Ein zentrales Ziel besteht darin, Karlsruhe für die Folgen durch den Klimawandel fit zu machen. Um das Stadtklima zu verbessern, müssen wir Wege finden, um Begrünungen von Straßen, Fassaden und Grünflächen über das gesamte Stadtgebiet hinweg auszudehnen. Infolge der Klimaveränderungen werden Extremwetterereignisse zunehmen, weshalb wir uns auf Hitze, Stürme und ungewöhnlich hohe Regenmengen vorbereiten müssen.
</p>
<p>
  Auf Initiative der SPD arbeitet die Stadt an einem Hitzeaktionsplan. Dieser Plan soll dazu beitragen, diejenigen, die in unserer Stadt besonders von den Auswirkungen der Klimakrise betroffen sind, durch gezielte Maßnahmen zu schützen. Ein wesentlicher Bestandteil ist die flächendeckende Bereitstellung von kostenfreiem Trinkwasser im gesamten Stadtgebiet, dessen Ausbau in den kommenden Jahren dringend vorangetrieben werden muss. Die Trinkwasserstellen sind gut auszuschildern und barrierefrei zu gestalten. Das Angebot an öffentlichen, barrierefreien Toiletten soll ausgebaut und besser ausgeschildert werden.
</p>
<p>
  Versiegelte Flächen, beispielsweise am ZKM-Vorplatz, sollen, wo möglich, entsiegelt und begrünt werden.
</p>
<p>
  Moderne Stadtentwicklung orientiert sich an den Alltagsgewohnheiten der Menschen. Das gilt in besonderer Weise für die Wegeplanung und die Planung von ÖPNV. Dazu ist es sehr wichtig, die unterschiedlichen Perspektiven und Bedarfe unterschiedlicher Personengruppen zu ermitteln und zu berücksichtigen.
</p>
<p>
  Unsere politische Ausrichtung zielt darauf ab, ein harmonisches Miteinander in Karlsruhe zu fördern. Dazu möchten wir das nachbarschaftliche Zusammenleben in den verschiedenen Stadtteilen gezielt unterstützen. Durch die Einführung eines Quartiersmanagements sollen die Bedürfnisse von Bürger:innen jeden Alters Berücksichtigung finden. Unser Ziel ist es, gesellschaftliche Teilhabe für alle zu ermöglichen und die Vernetzung unterschiedlicher Gruppen und Angebote im Nahbereich zu fördern. Dies erfordert eine Stärkung der sozialen Quartiersentwicklung. Hierbei setzen wir darauf, das Ehrenamt durch professionelle Strukturen voranzubringen und zu unterstützen. Zusätzlich ist es wichtig, niedrigschwellige Fördermöglichkeiten für bürgerschaftliche Initiativen in den Stadtteilen bereitzustellen.
</p>

<h3>Visionen einer neuen Innenstadt <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unsere Gesellschaft unterliegt ständigem Wandel. Als politische Vertreter:innen müssen wir frühzeitig auf Entwicklungen reagieren. Der Online-Handel hat sich längst fest in unserer Lebensrealität etabliert und beeinflusst auch Karlsruhe. Über die Jahre hinweg haben wir beobachtet, dass sich immer weniger Unternehmen in der Innenstadt ansiedeln. Die Innenstadt benötigt eine ausgewogene Vielfalt an Angeboten, um attraktiv zu bleiben. Sie muss zu einem lebendigen Lebensort für alle werden. Gleichzeitig ist es wichtig, auch Anreize für Unternehmen zu schaffen, sich hier anzusiedeln.
  </p>
  <p>
    Uns liegt daran, eine stadtweite Gestaltung zu erreichen, die für alle Generationen ausreichend Platz und Angebote bereithält. Dazu ist es unerlässlich, konsequente Barrierefreiheit zu gewährleisten. Kinder und Jugendliche benötigen Treffpunkte und Spielgelegenheiten, auch im Zentrum unserer Stadt. In diesen Veränderungsprozess sollen auch leerstehende Gebäude einbezogen werden. Im Stadtzentrum muss es einladende Orte geben, an denen man sich aufhalten kann, ohne etwas bezahlen zu müssen. Zusätzlich zu den bestehenden Angeboten in den Stadtteilen soll, wie seit langer Zeit von verschiedenen Jugendverbänden gefordert, ein Jugendzentrum in der Innenstadt entstehen. In einer Stadt, die für alle Bürger:innen da ist, müssen solche Angebote selbstverständlich sein. Wir betrachten die Neugestaltung des öffentlichen Raums als Chance für die gesamte Gesellschaft und möchten diese aktiv mitgestalten.
  </p>
  <p>
    Bereits in den Grundzügen der bisherigen Stadtplanung wurden emissionsfreie Verkehrskonzepte und Stadtbegrünung aufgenommen. Fassaden von Privathäusern und Industriegebäuden sollen begrünt werden. Hierfür müssen entsprechende Förderkonzepte entwickelt werden. Gemeinsam mit den Bürger:innen möchten wir Modelle erarbeiten, wie unsere Innenstadt zukünftig gestaltet sein soll. Wir sind der Überzeugung, dass wir den Verkehr innerhalb der Innenstadt besser organisieren müssen. Zentral gelegene Parkmöglichkeiten müssen mehr dazu beitragen, den Raum, den parkende Autos einnehmen, für die Bevölkerung freizugeben.
  </p>
  <p>
    Der nächste Schritt besteht darin, den bisher von Autos genutzten Raum in der Innenstadt für alle Bürger:innen zugänglich zu machen. Unser Ziel ist eine Innenstadt, die Gastronomie, Handel und Kultur miteinander verbindet.
  </p>
  <p>
    Die SPD Karlsruhe setzt sich dafür ein, mehr Sitzgelegenheiten in der Innenstadt zu schaffen.
  </p>
</div>

<h3>Kommunale Daseinsvorsorge <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die öffentliche Hand hat die Verantwortung, sicherzustellen, dass alle Bewohner:innen Karlsruhes mit ihren grundlegenden Bedürfnissen angemessen versorgt sind. Dies schließt nicht nur Wasser und Strom ein, sondern auch die lokale Gesundheitsversorgung, den Ausbau des Breitbandnetzes und die öffentliche Nahversorgung.
  </p>
  <p>
    Ein herausragender Breitbandausbau führt nicht nur zu höherer Zufriedenheit in der Bevölkerung, sondern auch der Wirtschaftsstandort Karlsruhe profitiert erheblich davon. Der Ausbau, einschließlich Glasfaser, muss zügig vorangetrieben werden. Das KA-WLAN soll an allen Haltestellen und in öffentlichen Gebäuden, wie beispielsweise Jugendzentren sowie in allen öffentlichen Bussen und Bahnen, verfügbar sein.
  </p>
  <p>
    Es ist von entscheidender Bedeutung, kontinuierlich an der Versorgung der Stadtviertel mit Supermärkten, Bioläden, Metzgereien usw. zu arbeiten. Die regelmäßige Einbeziehung der Bevölkerung in die Bewertung der lokalen Situation ist erforderlich und soll die Bedürfnisse der Bewohner:innen angemessen berücksichtigen.
  </p>
  <p>
    Unser Ziel ist es, zu gewährleisten, dass das städtische Klinikum die Karlsruher Bevölkerung optimal versorgt. Außerdem soll das Klinikum die Versorgung mit Schwangerschaftsabbrüchen sicherstellen. Dies gilt für alle Arten des Abbruchs, auch für solche nach der Beratungslösung. Die Notaufnahme muss effizient organisiert sein und schnelle Hilfe bieten. Der Neubau des Hauses M war ein wichtiger Schritt, und nun muss der Fokus auf der Renovierung der bestehenden Einrichtungen liegen. Die Beschäftigten müssen unter guten Arbeitsbedingungen tätig sein und angemessen entlohnt werden.
  </p>
  <p>
    Die ärztliche Versorgung in Karlsruhe ist, wie in vielen anderen Regionen, angespannt. Insbesondere Hausarztpraxen sind ungleich über das Stadtgebiet verteilt. In den Randbezirken von Karlsruhe herrscht bereits heute eine deutliche Unterversorgung. Insbesondere junge Ärzt:innen scheuen sich davor, bestehende Hausarztpraxen zu übernehmen, und bevorzugen stattdessen Anstellungsverhältnisse. Die Stadt soll Ärztehäuser einrichten, um eine flächendeckende Versorgung zu gewährleisten. Wir fordern die Stadt auf, Ärzt:innen bei der Suche nach geeigneten Räumlichkeiten zu unterstützen.
  </p>
  <p>
    Die Hausarztversorgung muss insbesondere in ländlichen Gebieten optimiert werden. Die Wartezeiten für Facharzttermine sind oft lang. Durch die Förderung von medizinischen Versorgungseinrichtungen müssen wir auch auf kommunaler Ebene unseren Beitrag leisten.
  </p>
  <p>
    Der attraktive Einzelhandel stellt das Markenzeichen von Innenstädten dar. Durch die Schaffung geeigneter Rahmenbedingungen sollen optimale Voraussetzungen für einen florierenden Einzelhandel geschaffen werden. Dies umfasst die Bereitstellung einer erstklassigen Infrastruktur, eine gute Anbindung sowohl an den öffentlichen Nahverkehr als auch an den Individualverkehr sowie die Gestaltung eines ansprechenden Stadtbilds an den jeweiligen Standorten.
  </p>
</div>

<h2>5. Die Stadt des guten Wohnens</h2>
<p>
  In Karlsruhe leben ungefähr 300.000 Menschen in etwa 150.000 Haushalten. Etwa zwei Drittel der Bevölkerung wohnen zur Miete, während ein Drittel im Eigentum lebt.
</p>
<p>
  In Karlsruhe verwenden Haushalte durchschnittlich etwa 30 % ihres Einkommens für Wohnkosten. Allerdings weisen Alleinerziehende sowie alleinlebende Personen ab 65 Jahren mit 39,0 % bzw. 37,6 % eine spürbar höhere Belastung durch Mietkosten auf. Zusätzlich werden rund 20 % der Miethaushalte in der Stadt vom Statistischen Bundesamt als &quot;überbelastete Haushalte&quot; klassifiziert, was bedeutet, dass sie mehr als 40 % ihres verfügbaren Einkommens für Mietzahlungen aufwenden.
</p>
<p>
  Als SPD Karlsruhe verfolgen wir bundes- und landesweite Entwicklungen auf konstruktive Weise und sehen das Projekt &quot;Neue Wohnungsgemeinnützigkeit&quot; der Ampelregierung als herausragende Möglichkeit für unsere Stadt.
</p>
<p>
  Wir setzen uns für den Ausbau von barrierefreiem Wohnraum in Karlsruhe ein.
</p>

<h3>Wohnraumförderung <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Aufgrund der angespannten Situation auf dem Mietwohnungsmarkt haben wir in Karlsruhe seit vielen Jahren aktiv Maßnahmen ergriffen, um dem Trend entgegenzuwirken. Mit dem &quot;Karlsruher Wohnraumförderungsprogramm&quot; (KAWOF) und dem &quot;Karlsruher Innenentwicklungskonzept&quot; (KAI) wurden zwei Initiativen ins Leben gerufen, die zur Schaffung von bezahlbarem Mietwohnraum beigetragen haben. Seit 2019 konnten insgesamt 1200 geförderte Wohnungen errichtet werden Als SPD Karlsruhe beabsichtigen wir, unbeirrt an diesem eingeschlagenen Kurs festzuhalten und die Fördermittel zu erweitern.
  </p>
</div>

<h3>Wohnraumakquise stärken <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Ein weiteres erfolgreiches Modell aus Karlsruhe ist die &quot;Wohnraumakquise durch Kooperation&quot;. Das Ziel dieses Programms ist es, bezahlbaren Wohnraum für Menschen zu schaffen, die von Wohnungslosigkeit bedroht oder betroffen sind. Dabei erwirbt die Stadt von privaten Vermieter:innen ein Belegerecht für mindestens 10 Jahre, begleitet von einer Mietausfallgarantie von 5 Jahren. Zusätzlich werden den Vermieter:innen Zuschüsse für Modernisierungsarbeiten gewährt. Dank dieses Ansatzes konnten bereits über 800 Wohnungen in Karlsruhe vermittelt werden.
  </p>
</div>

<h3>Flächenvergabe <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Nicht nur die erheblich gestiegenen Baukosten, sondern vor allem die begrenzten bebaubaren Flächen stellen eine große Herausforderung bei der Schaffung von Wohnraum in allen Preissegmenten dar. Mit der sogenannten &quot;Vergabe nach Konzept&quot; hat die Stadtverwaltung eine langjährige Forderung der SPD aufgegriffen: Zukünftig werden städtische Flächen nicht an den Höchstbietenden vergeben, sondern an diejenigen mit der stimmigsten Projektidee. Dabei werden neben der Schaffung von bezahlbarem Wohnraum auch Faktoren wie Mehrgenerationenwohnen oder die kreative Integration kleiner Gewerbeeinheiten bewertet. Diese neuen Vergaberichtlinien stärken auch die Position der vielen bestehenden und neuen Baugenossenschaften in Karlsruhe. Wir werden auch in Zukunft darauf achten, dass die begrenzten freien Flächen kreativ, sozialverträglich und ökologisch sinnvoll bebaut werden.
  </p>
  <p>
    Einer effektiveren Umnutzung von bebauten Flächen räumen wir dabei eine hohe Priorität ein.
  </p>
</div>

<h3>Die Rolle der Volkswohnung <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die städtische Tochtergesellschaft Volkswohnung erfüllt eine zentrale Aufgabe bei der Schaffung von bezahlbarem Wohnraum in Karlsruhe. Sie stellt 65% des geförderten Wohnraums in unserer Stadt. Durch niedrige Mieten der freifinanzierten Wohnungen haben die Wohnungen der Volkswohnung eine erheblich dämpfende Wirkung auf das Mietniveau in Karlsruhe. Wir wollen die Volkswohnung auf ihrem Weg zur Klimaneutralität unterstützen. Menschen, die auf geförderten Wohnraum angewiesen sind, müssen dennoch von der Energie- und Wärmewende profitieren können. Die SPD sieht sich als starken Partner der Volkswohnung und schließt eine jährliche Gewinnabschöpfung durch die Stadt Karlsruhe aus.
  </p>
</div>

<h3>Vernetzung <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Laut Prognosen werden in Karlsruhe bis zum Jahr 2035 etwa 14.000 zusätzliche Wohneinheiten in verschiedenen Preissegmenten benötigt. Die Schaffung von Wohnraum erfordert daher eine kooperative Zusammenarbeit aller Beteiligten in der Wohnungswirtschaft. Aus diesem Grund wurde auf Initiative der SPD das &quot;Akteurstreffen Wohnungsbau&quot; ins Leben gerufen, das als Plattform für Vernetzung und Austausch dient.
  </p>
  <p>
    Mittelfristig beabsichtigen wir die Etablierung einer &quot;Stabstelle Wohnungsbau&quot; innerhalb der Stadtverwaltung. Diese interdisziplinäre Einrichtung soll beispielsweise als Ansprechpartner für Wohnungsbauvorhaben fungieren und somit Planungs- und Genehmigungsprozesse beschleunigen. Sie soll die Vernetzung innerhalb der Wohnungswirtschaft weiter vorantreiben und eine breite &quot;Allianz zur Schaffung von bezahlbarem Wohnraum&quot; ins Leben rufen.
  </p>
  <p>
    Als SPD Karlsruhe setzen wir uns dafür ein, die Vernetzungsplattform auch auf die Umlandgemeinden auszuweiten. Nur im regionalen Verbund können wir den enormen Herausforderungen der Wohnraumschaffung erfolgreich begegnen.
  </p>
</div>

<h3>Wohnen für Studierende und Auszubildende <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    An den neun Karlsruher Hochschulen sind mehr als 42.000 Studierende eingeschrieben, von denen etwa die Hälfte in Karlsruhe ihren Wohnsitz hat. Aufgrund des vergleichsweise geringen monatlichen Durchschnittseinkommens der Studierenden zählen diese oft zu den &quot;überlasteten Mieter:innen&quot;. Sie geben einen erheblichen Anteil ihres Einkommens für Mietkosten aus. Nur etwa ein Zehntel der Karlsruher Studierenden kann auf einen günstigen Wohnheimplatz zurückgreifen.
  </p>
  <p>
    Zudem fehlt es in Karlsruhe an Wohnheimplätzen für Auszubildende, da diese zu Wohnheimen für Studierende überwiegend keinen Zugang haben.
  </p>
  <p>
    Somit befindet sich die Mehrheit der Studierenden und Auszubildenden auf dem freien Wohnungsmarkt und konkurriert mit anderen einkommensschwachen Gruppen um bezahlbaren Wohnraum. Daher ist es ein wichtiges Anliegen der SPD Karlsruhe, die Schaffung von Wohnraum für Studierende und Auszubildende verstärkt in den Fokus der städtischen Wohnraumpolitik zu rücken. Ein Lösungsansatz besteht darin, dass die öffentlichen Wohnheimträger bei der Zuteilung von geeigneten Flächen und der Verdichtung bestehender Anlagen bevorzugt unterstützt werden.
  </p>
  <p>
    Ein weiteres Anliegen der SPD ist, dass die Volkswohnung ihre Ressourcen nutzt, um entweder selbst Wohnraum für Studierende und Auszubildende zu schaffen und zu vermieten oder in Kooperation mit öffentlichen Wohnheimträgern entsprechende Objekte zu entwickeln.
  </p>
</div>

<h3>Programm „Wohnungstausch – gemeinsam profitieren“ <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Wir möchten die gewonnenen Erkenntnisse aus der Wohnraumakquise nutzen und das neue Programm &quot;Wohnungstausch – gemeinsam profitieren&quot; einführen. Es kommt häufig vor, dass große Wohnungen von nur wenigen Personen bewohnt werden, während gleichzeitig junge Familien dringend mehr Wohnraum benötigen.
  </p>
  <p>
    Unser Vorschlag: Die Stadt Karlsruhe soll eine Wohnungstauschbörse ins Leben rufen. Neben der Möglichkeit des Wohnungstauschs sollen Menschen auch bei der Untervermietung Unterstützung erhalten. Durch Umzugszuschüsse und Hilfe bei vertraglichen Angelegenheiten können zusätzliche Anreize geschaffen werden.
  </p>
</div>

<h2>6. Klimagerechtigkeit und Mobilität</h2>
<h3>Klimaschutz und Nachhaltigkeit <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unter breiter Beteiligung der Öffentlichkeit wurde im Jahr 2020 ein umfassendes „Klimaschutzkonzept 2030“ (KSK 2030) entwickelt, das mit fünf Themenschwerpunkten einen klaren Pfad aufzeigt, wie sich Karlsruhe bis 2040 zu einer klimaneutralen Kommune entwickeln soll. Die insgesamt 76 Einzelmaßnahmen wurden unter breiter Beteiligung der Öffentlichkeit erarbeitet und im April 2020 vom Gemeinderat beschlossen.
  </p>
  <p>
    Seitdem haben wir viele Ressourcen in den Bereich des Klimaschutzes gelenkt. Trotz schwieriger Haushaltslage konnten seit Aufstellung des Klimaschutzkonzeptes erhebliche Mittel und Stellen in den Klimaschutz gelenkt werden, auch auf Antrag der SPD.
  </p>
  <p>
    Auch in den kommenden Haushaltsjahren wollen wir als SPD Karlsruhe den Klimaschutz in unserer Stadt engagiert vorantreiben, und zwar unabhängig von der Haushaltslage. Wir legen dabei unseren Fokus auf eine klare Priorisierung der Maßnahmen, jedoch ohne die Notwendigkeit einer breiten Ausfinanzierung des KSK aus den Augen zu verlieren.
  </p>
  <p>
    Uns ist wichtig, dass eine klimaneutrale Stadt nur im engen Schulterschluss mit der gesamten Bevölkerung, der Wirtschaft und den städtischen Gesellschaften gelingen kann. Deshalb legen wir großen Wert darauf, dass auch weiterhin eine möglichst breite Beteiligung aller Akteur:innen ermöglicht wird. Austauschplattformen wie der Karlsruher Naturschutzbeirat und der Klimaschutzbeirat sind uns wichtig.
  </p>
  <p>
    Unseren städtischen Gesellschaften kommt als öffentlich getragene Wirtschaftsunternehmen eine wichtige Vorbildfunktion zu. Deshalb setzten wir uns mit aller Kraft dafür ein, dass sie ihre Rolle in der gesamtstädtischen Klimaschutzstrategie erfüllen.
  </p>
  <p>
    Die Stadtwerke Karlsruhe leisten mit der Bereitstellung von Fernwärme bereits heute einen wichtigen Beitrag zur klimaneutralen Stadt. Die größte Herausforderung, der wir uns in den kommenden Jahren stellen müssen, ist die Dekarbonisierung der Wärmeerzeugung. Dazu müssen alle Möglichkeiten ausgeschöpft werden, von der Geothermie bis zu Großwärmepumpen. In Stadtteilen, in denen kein Anschluss an die Fernwärme erfolgen kann, müssen nach Möglichkeit klimafreundliche Nahwärmenetzte geschaffen werden.
  </p>
  <p>
    Der Ausbau der Photovoltaik muss in Karlsruhe dringend beschleunigt werden. Wir fordern, dass alle städtischen Gebäude in den kommenden Jahren zur Energiegewinnung genutzt werden, auch durch Kooperationen mit Bürgerenergiegenossenschaften oder anderen Akteuren.<br>
    Die Energieleit- und Wärmeplanung der Stadt Karlsruhe bildet das Grundgerüst für die Energie– und Wärmewende, die in den nächsten Jahren konkretisiert werden soll. Wir setzen uns in diesem Prozess für Planbarkeit und Transparenz ein, die sowohl Hausbesitzer:innen als auch Mieter:innen für die Umsetzung brauchen.
  </p>
  <p>
    Die Verkehrsbetriebe Karlsruhe sind die Treiber der Verkehrswende in unserer Stadt. Wir wollen uns dafür einsetzen, dass gestiegene Kosten nicht zu Lasten der Qualität des Öffentlichen Personen Nahverkehrs (ÖPNV) in Karlsruhe gehen und eine möglichst hohe Taktung beibehalten wird. Darüber hinaus wollen wir das Netz erweitern, um die Attraktivität des ÖPNV weiter zu steigern.
  </p>
</div>

<h3>Mobilität/Verkehr in Karlsruhe <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unser Ziel ist, dass sich in Karlsruhe alle Menschen schnell, umweltfreundlich, komfortabel, günstig und sicher in der Stadt bewegen können. Dabei ist für uns barrierefreie Mobilität von besonderer Bedeutung.
  </p>
  <p>
    Fußverkehr und öffentlicher Personennahverkehr haben für uns die höchste Priorität im Sinne der Verkehrswende. Außerdem streben wir an, den bereits starken Fahrradverkehr in Karlsruhe weiter zu fördern.
  </p>
  <p>
    Um Konflikte zu verhindern und eine höhere Sicherheit zu gewährleisten, halten wir es für wichtig, die Verkehrswege so weiterzuentwickeln, dass Auto- und Fahrradverkehr getrennt voneinander stärker gebündelt über große Achsen geleitet werden.
  </p>
  <p>
    Die einfache übergreifende Nutzung verschiedener Verkehrsträger möchten wir fördern. Dazu gehört die Vereinfachung des Umstiegs in Form von Mobilitäts-Ports, an denen mehrere Verkehrsträger zusammenkommen, und die Unterstützung von Mobilitätsdiensten wie RegioMOVE, die technisch den Umstieg begleiten und damit erleichtern.
  </p>
  <p>
    Die Weiterentwicklung der Mobilität in Karlsruhe betrifft uns alle in unserem Alltag. Daher sind Beteiligungsformate, durch die Bürger:innen ihre Anliegen einbringen können, selbstverständlich wichtig.
  </p>
  <p>
    Um die Finanzierung der notwendigen Infrastrukturmaßnahmen gewährleisten zu können, betrachten wir die Einführung einer City-Maut oder einer Nahverkehrsabgabe, in Abstimmung mit dem Landkreis, als notwendig.
  </p>
  <p>
    Die SPD Karlsruhe unterstützt die Planung der neuen Bahnstrecke von Mannheim nach Karlsruhe und setzt sich für eine menschenverträgliche Realisierung ein.
  </p>
</div>

<h3>Fußverkehr <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Unser Ziel ist, dass Fußgänger:innen sich wohlfühlen und sich sicher fortbewegen können. Deshalb wollen wir Verkehrswege, die ihnen vorbehalten sind.
  </p>
  <p>
    Fußwege müssen barrierefrei und ausreichend breit sein, sie dürfen nicht durch das Abstellen von Fahrzeugen oder Gegenständen reduziert werden. Wir wollen die Verleihangebote für E-Scooter besser auf die Interessen anderer Verkehrsteilnehmer:innen abstimmen. Dazu möchten wir insbesondere steuern, wo die E-Scooter abgestellt werden.
  </p>
  <p>
    Wichtig ist dabei insbesondere die Abgrenzung der Fußwege von Radwegen. Das gilt sowohl für die Wege neben großen Straßen als auch für die Innenstadt.
  </p>
  <p>
    Wir möchten Ampelschaltungen überprüfen, damit diese neben dem ÖPNV besonders auf den Fußverkehr abgestimmt sind.
  </p>
</div>

<h3>Öffentlicher Personennahverkehr (ÖPNV) <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    In der vergangenen Wahlperiode wurde die Kombilösung in Betrieb genommen. Damit wurde ein Meilenstein für den ÖPNV in Karlsruhe und die Weiterentwicklung der Innenstadt erreicht.
  </p>
  <p>
    Durch die Corona-Pandemie hat die Nutzung des ÖPNV stark abgenommen. Wir verfolgen das Ziel, den ÖPNV so attraktiv zu gestalten, dass er deutlich stärker genutzt wird.
  </p>
  <p>
    Besonders wichtig ist uns der Ausbau des ÖPNV für mehr Kapazität und eine bessere Anbindung, gerade in Randlagen und in der Nacht.
  </p>
  <p>
    ÖPNV muss zuverlässig und planbar sein. Das bedeutet für uns insbesondere zuverlässige Informationen zu Fahrplänen und frühzeitige Meldung von Ausfällen und Verspätungen. Die letzten Jahre war die Zahl der Zugausfälle deutlich zu hoch, was auch stark am Personalmangel lag. Die SPD Karlsruher setzt sich für bessere Arbeitsbedingungen und faire Bezahlung der Angestellten ein. Für alle Menschen mit besonderen Bedarfen muss eine Mobilitätsgarantie gewährleistet sein.
  </p>
  <p>
    Die Barrierefreiheit von Haltestellen und Zügen ist ausbaufähig. Unter Barrierefreiheit meinen wir nicht nur die Barrierefreiheit für Rollstuhlfahrer:innen, sondern auch Ansagen für sehbehinderte und blinde Menschen, optische Signale für Gehörlose und Schwerhörige oder einfache Darstellungen von Wegen und Fahrplänen für Menschen mit kognitiven Behinderungen. Übrigens: Alles, was Menschen mit Behinderungen die Teilhabe am Leben ermöglicht, ist auch immer eine große Unterstützung für Senior:innen, Familien mit Kindern und für vorübergehend erkrankte Menschen.
  </p>
  <p>
    Mit dem 49 €-Ticket des Bundes und dem 365 €-Jugendticket des Landes wurde ein neues Kapitel für Preise im ÖPNV für regelmäßig Fahrende eröffnet. Als SPD Karlsruhe ist es dennoch selbstverständlich, dass es für Karlsruher Passbesitzer:innen weiterhin vergünstigte Fahrkarten gibt. Den eingeschlagenen Weg wollen wir auch in Zukunft konstruktiv begleiten, mit dem klaren Fokus auf eine gerechte Preisstruktur, die den ÖPNV stärkt und für alle bezahlbar macht.
  </p>
  <p>
    Darüber hinaus möchten wir, dass der Nahverkehrsentwicklungsplan für die langfristige Entwicklung unseres ÖPNV kontinuierlich weiterentwickelt und umgesetzt wird – unter anderem mit dem Ziel eines besseren ÖPNV von und in die Pfalz und das Elsass, zum Beispiel durch Taktverdichtung der Bahnen, Elektrifizierung und zweigleisigen Ausbau der Strecke Wörth-Landau sowie bessere Busanschlüsse.
  </p>
</div>

<h3>Radverkehr <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Das Fahrrad ist in Karlsruhe bereits das meistgenutzte Verkehrsmittel im innerstädtischen Verkehr. Wir wollen den Radverkehr weiter stärken.
  </p>
  <p>
    Radfahren muss sicherer werden. Daher möchten wir die Radwege in den kommenden Jahren auf allen Hauptrouten durch bauliche Barrieren von der Straße trennen. Abbiegebeziehungen ist dabei eine besondere Aufmerksamkeit zu schenken. Im Idealfall verlaufen die Hauptverkehrsachsen für motorisierten und Fahrradverkehr auf unterschiedlichen Straßen durch das Stadtgebiet.
  </p>
  <p>
    Außerdem muss Radfahren schnell und einfach sein. Daher setzten wir uns für eine zügige Umsetzung der geplanten Radschnellwege in Karlsruhe ein. Dabei ist eine Ost-West-Achse von Durlach über den Hauptbahnhof nach Westen besonders wichtig.
  </p>
  <p>
    Wir möchten die Abstellflächen für Fahrräder, insbesondere für die immer häufiger genutzten Lastenräder, ausbauen. Dazu sollen bisherige Parkflächen für Autos umgestaltet werden.
  </p>
  <p>
    Um Transporte mit dem Fahrrad, statt mit dem Auto zu fördern, möchten wir in Karlsruhe im Rahmen von KVV.nextbike Lastenräder zum Verleih anbieten und auch weitere Lastenradverleih-Anbieter fördern.
  </p>
  <p>
    Mit stärkeren Kontrollen durch die Ordnungsbehörden sollen Konflikte zwischen Fuß-, Rad- und Autoverkehr vermieden und dadurch die Verkehrssicherheit für alle verbessert werden.
  </p>
</div>

<h3>Motorisierter Verkehr <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Um Stadtteile und besonders Wohngebiete zu entlasten, möchten wir den Autoverkehr so stark wie möglich auf große Hauptachsen konzentrieren und Umfahrungen ermöglichen. Wohngebiete sollen unter anderem durch Beschränkung der Zufahrt für Anlieger ruhiger werden. Dazu zählt ausdrücklich der Bau der Umfahrung von Hagsfeld.
  </p>
  <p>
    Wir streben die Ausweitung von 30er-Zonen in der Stadt an.
  </p>
  <p>
    Außerdem möchten wir Anwohner:innen von stark genutzten Straßen vor Lärm schützen. Dazu gehört insbesondere auch eine Überdeckelung der Südtangente und - sofern sie kommt - eine Anbindung der zweiten Rheinbrücke an die B36.
  </p>
  <p>
    Um den Anteil der Autos im Pendelverkehr zu reduzieren, unterstützen wir die Bildung von Fahrgemeinschaften.
  </p>
  <p>
    Durch die Förderung anderer Verkehrsmittel streben wir an, das Auto im Alltag in Karlsruhe verzichtbar zu machen. Unser Ziel ist, damit die Zahl der Autos in Karlsruhe zu reduzieren. Wir erhoffen uns durch die darauffolgende Reduktion der Parkflächen auch eine lebenswertere Stadt.
  </p>
  <p>
    Um ein eigenes Auto entbehrlich zu machen, fördern wir Car-Sharing-Angebote, indem wir öffentliche Parkplätze in Stellplätze für Car-Sharing umwandeln und ordentlich ausschildern.
  </p>
  <p>
    Auch für Fahrten aus dem Umland in die Stadt möchten wir es attraktiver machen, am Rande des Stadtgebiets zu parken und mit dem ÖPNV in die Stadt zu fahren. Dazu gehört auch ein günstiges Ticket für die Fahrt in die Stadt.
  </p>
</div>

<h2>7. Die Stadt der guten Arbeit und starken Wirtschaft</h2>
<h3>Wirtschafts- und Industriestandort <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Karlsruhe ist ein starker Wirtschaftsstandort und bietet beste Voraussetzungen für die Zukunft. Als einer der führenden Standorte der Informations- und Kommunikationstechnologie (IKT) kann sich Karlsruhe in Verbindung mit dem Industrie-, Wissenschafts- und Handelsstandort zum Motor der Digitalisierung Europas entwickeln.
  </p>
  <p>
    Der Digitale Wandel wird alle gesellschaftlichen Bereiche in den nächsten Jahren in einer bisher unbekannten Dimension erfassen und verändern. Wir in Karlsruhe haben die notwendigen Rahmenbedingungen diesen Wandel zu gestalten, für Bürger:innen, für die Unternehmen, für die Wissenschaft und für die Verwaltung. Diese Chance kann nur dann richtig genutzt werden, wenn wir einen ganzheitlichen Ansatz wählen. Der Wirtschaftsstandort Karlsruhe kann sich nur dann erfolgreich weiterentwickeln, wenn wir Handwerk, Handel, Wissenschaft, traditionelle und Hightech-Industrie nicht als Gegensätze, sondern als Einheit mit sich gegenseitig unterstützenden Faktoren betrachten und gleichermaßen fördern.
  </p>
  <p>
    Die SPD Karlsruhe versteht sich als Förderer und Ideengeber für die Stadt Karlsruhe und die gesamte umliegende Technologieregion. Wir wissen, wie wichtig es ist, sich ständig weiterzuentwickeln. Diesen Weg wollen wir gemeinsam mit den Interessenvertretungen und Verbänden aller Branchen erarbeiten und weiter vorantreiben.
  </p>
  <p>
    Neben der Revitalisierung des Technologieparks Karlsruhe, hat die SPD Karlsruhe den Weg für den „Smart Production Park“ geebnet, denn wir haben erkannt: Karlsruhe muss die Grundlage zur Erprobung und Entwicklung hochqualifizierter, innovativer Produktionsarbeitsplätze schaffen. Innovation und Gründungen sind die Grundlage für eine zukunftsfähige Wirtschaft. Deshalb wollen wir zielstrebig darauf hinwirken, die besten Bedingungen für neuartige, digitale Produktionsabläufe vor Ort zu schaffen. Wir wollen die Förderung von Start-Up-Unternehmen gezielt verstärken. Ebenso sollen Genossenschaften in ihrer Gründung unterstützt und gefördert werden.
  </p>
</div>

<h3>Handwerk- und Dienstleistungsbetriebe <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Ein erfolgreicher und expandierender Wirtschaftssektor benötigt starke Handwerks- und Dienstleistungsbetriebe. Wir verstehen uns als wirksamer und verlässlicher Partner der regionalen Handwerkskammer, der Innungen, der Gewerkschaften und der Dienstleistungsbranche. All diese Betriebe brauchen Fläche. Wir wollen deshalb die vorhandenen Gewerbeflächen optimieren und darauf achten, dass auch klein- und mittelständische Betriebe die Möglichkeit erhalten, Gewerbeflächen bedarfsgerecht zu entwickeln. Nachwuchs im Handwerk wollen wir mit einem eigenen Gründerzentrum für Handwerksbetriebe den Einstieg in die Selbstständigkeit vereinfachen.
  </p>
  <p>
    Die SPD Karlsruhe stellt sich der besonderen Herausforderung eines verantwortungsvollen Flächenmanagements im Spannungsfeld zwischen Wirtschaft, Wohnen und Ökologie. Die begrenzt zur Verfügung stehenden Flächen unterliegen auch zur Gewerbeentwicklung einem starken Konkurrenzdruck. Daher ist eine gezielte Flächenentwicklung weiterhin dringend notwendig. In den vergangenen Jahren wurden strategische Flächen wieder erworben. Nur so kann eine Abwanderung von Firmen vermieden werden
  </p>
  <p>
    Wir wollen uns zukünftig noch stärker für moderne und zeitgemäße Bebauungspläne einsetzen, die Gewerbeflächen und Wohnraum kombinieren. Wir wollen bestehende Gewerbeflächen weiter optimieren und alle Möglichkeiten prüfen, den Bestand effizienter zu nutzen.
  </p>
  <p>
    Bei großen, zusammenhängenden Gebieten im Randbereich unserer Stadt wollen wir vorrangig auf Hightech- und Digitalunternehmen setzen, die unser Profil als „Digitale Zukunftsstadt“ weiter stärken, jedoch ohne bestehende Industrie- oder Handelsunternehmen zu verdrängen.
  </p>
  <p>
    Die SPD Karlsruhe ist sich bewusst, dass Flächenpolitik auch über die Stadtgrenze hinaus stattfinden muss. Mit der TechnologieRegion Karlsruhe hat man darüber hinaus einen starken Partner für die auch weiterhin im Fokus stehende Vernetzung der Karlsruher Unternehmen mit denen aus der Region.
  </p>
</div>

<h3>Fachkräfte gewinnen, gute Arbeit fördern, faire Bezahlung <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Wir setzen uns dafür ein, dass Karlsruhe auch für neuzugewanderte Arbeits- und Fachkräfte attraktiv ist. Die entsprechenden Rahmenbedingungen wollen wir gemeinsam mit den Kammern, Kliniken und Pflegeeinrichtungen verbessern.
  </p>
  <p>
    Mit über 6000 Mitarbeiter:innen ist der „Konzern Stadt“ neben dem KIT der zweitgrößte Arbeitgeber in Karlsruhe. Die SPD Karlsruhe legt großen Wert auf die Belange unserer städtischen Mitarbeiter:innen.
  </p>
  <p>
    Wir machen uns für ein faires Arbeitsklima und gute Arbeit in unserer Stadt stark. Genau deswegen haben wir die Anhebung der „Eigenreinigungsquote“, damit die Hälfte der Reinigungsarbeiten in Schulen, Kitas und Verwaltungsgebäuden durch Mitarbeiter:innen der Stadt übernommen werden. Diese Quote wollen wir weiter erhöhen.
  </p>
  <p>
    Als solidarische Partei bestehen wir auf der Einhaltung von Tariftreue bei allen öffentlich vergebenen Aufträgen sowie deren Kontrolle durch die Stadt. Das bedeutet im Klartext: Nur noch Vergabe von städtischen Aufträgen an Unternehmen, die faire Löhne und Arbeitsbedingungen für ihre Mitarbeitenden garantieren.
  </p>
  <p>
    Die SPD Karlsruhe setzt sich für einen inklusiven Arbeitsmarkt ein. Weitere Firmen in der Region sollen inklusive Arbeitsplätze anbieten; die bereits existierenden Förderinstrumente zur Anstellung von Menschen mit Behinderungen müssen konsequenter und effizienter umgesetzt werden. So muss das Budget für Arbeit in Karlsruhe vermehrt Anwendung finden.
  </p>
</div>

<h3>Hochschulen und Wissenschaft <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Mit über 40.000 Studierenden ist Karlsruhe ein wichtiger Hochschulstandort in Europa.
  </p>
  <p>
    Karlsruhe soll auch in Zukunft für Studierende und Wissenschaftler:innen ein attraktiver Hochschul- und Forschungsstandort sein.
  </p>
  <p>
    Entscheidend für die Attraktivität für Studierende ist ausreichender Wohnraum für Studierende in Karlsruhe. Das Studierendenwerk und die gemeinnützigen Wohnheimträger werden im Rahmen der verfügbaren Möglichkeiten beim Bau und Ausbau von Wohnheimen unterstützt.
  </p>
  <p>
    Die breite studentische Kultur braucht weiterhin auf dem Campus und in der Stadt wie in Wohnheimen oder dem Studierendenzentrum Z10 Räume, damit Karlsruhe als studentische Stadt attraktiv bleibt. Um eine bessere Verknüpfung zwischen städtischen und studentischen Kulturangeboten zu erreichen, wollen wir die dauerhafte Vertretung der Studierenden im Kulturausschuss ermöglichen.
  </p>
  <p>
    Die Hochschulen und die Forschenden in Karlsruhe begreifen wir als Chance für die Entwicklung der Stadt. So kann die Stadt z.B. im Klimapakt Karlsruher Hochschulen gemeinsam mit den Hochschulen den Klimawandel bekämpfen, aber auch wissenschaftlich begleitete Projekte in der Stadtentwicklung umsetzen.
  </p>
</div>

<h2>8. Die Stadt der vielfältigen Kultur</h2>

<!-- <h3><i class="bi bi-caret-down-fill"></i></h3> -->
<div class="text-slide">
  <p>
    Unsere Stadt zeichnet sich durch eine gute Mischung aus sogenannter Breiten-, Sub- und Hochkultur aus mit vielen innovativen Ansätzen, wie zum Beispiel das Kulturzentrum Alter Schlachthof mit seinen vielfältigen Einrichtungen.
  </p>
  <p>
    Für uns als SPD sind alle Kulturschaffenden unverzichtbarer Bestandteil unserer Stadtgesellschaft. Deshalb erachten wir den Neubau des Badischen Staatstheaters für genauso wichtig wie die Schaffung von genügend Räumen für freie und nichtkommerzielle Kultureinrichtungen, wie zum Beispiel das Haus der Produktion.
  </p>
  <p>
    An Kunst und Kultur müssen alle Mitbürger:innen teilhaben können, Einrichtungen müssen selbstverständlich barrierefrei eingerichtet sein. Während der Corona-Pandemie geriet die Kulturszene in einen Ausnahmezustand. Der Gemeinderat beschloss Corona-Nothilfen, sodass kein Kulturbetrieb Insolvenz anmelden musste. Auch in Zukunft fordern wir die Stadt Karlsruhe auf, dass Kultureinrichtungen in Anbetracht extrem gestiegener Kosten weiterhin ihr vielfältiges Angebot aufrechterhalten können.
  </p>
</div>

<h3>Räume für Kultur <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Wie in vielen Großstädten ist auch in Karlsruhe der Raum sehr begrenzt. Für die freie Kulturszene oder Menschen, die sich zum kreativen Zusammensein treffen wollen, muss ebenfalls ausreichend Platz in Karlsruhe vorhanden sein. Wir haben uns in den vergangenen Monaten für die Realisierung des Hauses der Produktionen sowie ein neues Kulturzentrum in der Schauenburgstraße vehement eingesetzt. Die Planung von Bandprobe-Räumen in der ehemaligen Molkereizentrale begrüßen wir sehr und werden uns auch künftig für Projekte dieser Art stark machen.
  </p>
  <p>
    Programmkinos sind ebenfalls ein unverzichtbarer Bestandteil unserer Kulturlandschaft. Im Vergleich zu vielen Großstädten, in denen diese Sparte vernachlässigt wurde, werden wir uns bemühen, dass die Programmkinos auch weiterhin in Karlsruhe bestehen bleiben.
  </p>
  <p>
    In einigen Quartieren haben wir in den vergangenen Jahren Stadtteilbibliotheken errichtet. Diese tragen dazu bei, Räume für Kinder und Jugendliche zu schaffen, aber auch die Bevölkerung mit Literatur zu versorgen. Die SPD in Karlsruhe wird sich weiterhin dafür einsetzen, den Medienbus zu erhalten und auszubauen, um Bürger:innen in Stadteilen ohne Stadtteilbibliothek den Zugang zu Medien zu erleichtern. Die Stadtteile ohne Stadtteilbibliothek brauchen einen Zugang zu Medien ohne große Wege und Auto.
  </p>
  <p>
    Der Neu-und Umbau des Badischen Staatstheaters wurde beschlossen und wird nun umgesetzt. Dieser Schritt war notwendig, um die Arbeitsbedingungen der Beschäftigten zu verbessern. Nun werden wir das Projekt kritisch begleiten und ein besonderes Augenmerk auf die transparente Kostenentwicklung legen.
  </p>
  <p>
    Das Prinz-Max-Palais muss ebenfalls grundlegend saniert werden, wofür wir uns einsetzen.
  </p>
</div>

<h2>9. Die Stadt der aktiven Freizeit</h2>
<h3>Sportstadt Karlsruhe <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Karlsruhe ist eine Sportstadt. Insgesamt sind ein Drittel der Karlsruher Einwohner:innen in einem der vielen Sportverein aktiv. Für die SPD Karlsruhe ist die Förderung der Karlsruher Sportvereine wichtig. Damit die Vereine ihr großzügiges Programm ausweiten, anstatt verkleinern zu müssen, bedarf es dringend weiterer Sportstätten. Wir fordern daher mit Nachdruck, dass neben den neugebauten Sporthallen weitere Trainingsmöglichkeiten geschaffen werden, die sich an den Bedarfen der nutzenden Vereine orientieren. Eine stärkere Modularisierung und die Verwendung von Leichtbauweise können zu einer kostengünstigeren und schnelleren Umsetzung von Neubauplänen führen.<br>
    Die seit langen Jahren geplante Zusammenlegung von Sportflächen z.B. in Rüppurr und Durlach muss endlich weiter vorangehen. Wir werden uns dafür stark machen, dass alle Zusagen, die den beteiligten Vereinen im Vorfeld gemacht wurden, eingehalten werden. Ebenso ist die SPD für den Neubau der Sportanlage von ESG Frankonia und die Schaffung wohnortnaher Kleingärten an der Stuttgarter Straße. Auch Kleingärten bilden attraktive Freizeitmöglichkeiten und der Bedarf ist enorm.
  </p>
  <p>
    Die SPD Karlsruhe steht für eine strategische Sportförderung, die vor allem den Fokus auf den Schul- und Breitensport legt. Wir wollen die städtischen Förderrichtlinien weiterentwickeln mit dem Ziel, vor allem die jungen Menschen in unserer Stadt früh für Sport zu begeistern.<br>
    Die Sportmöglichkeiten, die allen Menschen, unabhängig von Vereins- und Clubmitgliedschaft, zur Verfügung stehen, wie beispielsweise weitere Ballspielfelder, sollen ausgeweitet werden.
  </p>
</div>

<h3>Bäderlandschaft <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Die Frei- und Hallenbäder sind für die SPD Karlsruhe ein wichtiger Bestandteil der Daseinsvorsorge in unseren Stadtteilen. Sie dienen nicht nur der sportlichen Betätigung und der Freizeitgestaltung, sondern sind wichtige soziale Treffpunkte für Menschen allen Alters und Herkunft. Was in der Vergangenheit galt, gilt auch für die Zukunft: Mit der SPD Karlsruhe wird es keine ersatzlose Bäderschließung geben!
  </p>
  <p>
    Wir wollen im Gegenteil wohnortnahe Bäder stärken und ausbauen. Dazu fordern wir die Stadtverwaltung auf, auch kreative Lösungen zu finden, wie zum Beispiel das ehrenamtlich betriebene Freibad „Wölfle“ in Wolfartsweier. Neben dem Erhalt der Stadtteilbäder fordert die SPD Karlsruhe den Ersatzbau eines Schwimmbades in Neureut und die Realisierung des Kombibads in Durlach. Des Weiteren setzen wir uns dafür ein, dass die Bäder von allen oberkörperfrei genutzt werden können.
  </p>
  <p>
    Schwimmbäder sind in der Unterhaltung teuer. Gestiegene Kosten dürfen aber nicht allein durch Erhöhung der Eintrittspreise kompensiert werden. Wir sehen die Stadt Karlsruhe in der Pflicht, durch höhere Bezuschussung das Niveau der Eintrittspreise auf einem stabilen Niveau zu halten. Menschen mit geringem Einkommen müssen besonders unterstützt werden. Deshalb fordern wir auch dauerhaft die Reduzierung des Eintrittspreises für Inhaber:innen des Karlsruher Passes.
  </p>
</div>

<h3>Zoologischer Stadtgarten <i class="bi bi-caret-down-fill"></i></h3>
<div class="text-slide">
  <p>
    Der zoologische Stadtgarten ist ein Publikumsmagnet und eine wichtige Grünanlage im Herzen der Stadt. Mit dem Zoo leistet Karlsruhe auch einen wichtigen Beitrag zum Artenschutz und diese Entwicklung wollen wir als SPD unterstützen. Wir wollen den Zoo kontinuierlich weiterentwickeln, um seine Attraktivität in der Stadt und über die Stadtgrenzen hinaus zu sichern.
  </p>
</div>

<h2>10. Die sichere und saubere Stadt</h2>
<p>
  Sicherheit und Sauberkeit in Karlsruhe sind zentrale Anliegen der SPD Karlsruhe. Sie sind Voraussetzung für eine hohe Lebensqualität in den Stadtteilen und in der Innenstadt.
</p>
<p>
  Wir stehen zur Arbeit des Kommunalen Ordnungsdienstes (KOD) und wollen ihn weiterhin stärken. Wir fordern, dass die Einsatzzeiten des KODs in Abstimmung mit dem Personalrat den Problemlagen angepasst werden.
</p>
<p>
  Die weitere Verbesserung der Sicherheit in Karlsruhe, sowohl die objektive als auch die subjektiv empfundene, ist für uns ein wichtiges Ziel. Bei der Verbesserung der Sicherheit sollen Präventions- und Deeskalationsmaßnahmen einen mindestens genauso hohen Stellenwert erhalten wie Ordnungsmaßnahmen. Wir wollen diesen Bereich ausbauen und gerade an „Brennpunkten“ die Vernetzung zwischen Polizeidienststellen, KOD und Sozialarbeit voranbringen. In diesem Sinne werden wir das Sicherheitskonzept der Stadt Karlsruhe begleiten.
</p>
<p>
  Und auch das liegt uns sehr am Herzen: Die neu gestaltete, straßenbahnfreie Innenstadt soll ober- und unterirdisch für alle Mitbürger:innen eine hohe Aufenthaltsqualität ohne jegliches Unsicherheitsgefühl bieten.
</p>
<p>
  Sauberkeit ist die „Vorstufe“ zur Sicherheit und geht uns alle an. Die Sauberkeit im Stadtbild trägt wesentlich zur Erhöhung des subjektiven Sicherheitsgefühls der Bürger:innen bei. Die Arbeit des Teams Sauberes Karlsruhe ist unverzichtbar und muss gestärkt werden. Sauberkeit und Ordnung bestimmen die allgemeine Lebens- und Aufenthaltsqualität im öffentlichen Raum. Sie sind ein wichtiges Merkmal für die Innenstadtentwicklung und von nicht unerheblicher Bedeutung für die ortsansässige Wirtschaft und den Tourismus. Nur eine saubere Stadt ist eine schöne Stadt.
</p>
<p>
  Wir wollen gerade der Verschmutzung unserer Innenstadt entgegentreten. Durch eine Kampagne sollen alle Bürger:innen sensibilisiert werden, Verantwortung für Sauberkeit in der Stadt zu übernehmen und Abfall im öffentlichen Raum entgegenzuwirken. Es darf nicht allein dem stetig steigenden Einsatz der Stadtreinigung überlassen werden, für Sauberkeit zu sorgen. Es bedarf einer Bewusstseinsänderung bei uns allen nach dem Motto: Der beste Abfall ist der, der gar nicht erst entsteht.
</p>

<h2>11. Für solide städtische Finanzen</h2>
<p>
  Unsere Stadt benötigt auch in den kommenden Jahren solide städtische Finanzen, um weiterhin in die Zukunft investieren zu können. Dies betrifft den Ausbau und die Qualität von Kinderbetreuungseinrichtungen, die soziale Infrastruktur, die dringend notwendige Sanierung städtischer Gebäude, insbesondere Schulen, sowie eine nachhaltige Stadtentwicklung und Mobilität. Die Fähigkeit zur Investition im städtischen Haushalt kommt allen Bürgerinnen und Bürgern Karlsruhes zugute. Ein haushaltstechnisch genehmigungsfähiger Plan wird auch zukünftig unumgänglich sein. Dabei ist für die SPD Karlsruhe klar: Soziales, Bildung und Klimaschutz sind unsere Priorität!
</p>
<p>
  Die Krisen der vergangenen Jahre haben nachhaltige Auswirkungen auf unsere städtischen Finanzen gehabt. Daher ist eine Haushaltsstrategie von großer Bedeutung, die auf eine systematische Priorisierung von Investitionen, den Abbau von Haushaltsrückständen und die Stabilisierung städtischer Beteiligungsunternehmen setzt. Die Schaffung neuer Einnahmequellen durch verstärkte Unternehmensgründungen und der Ausbau des Standortmarketings sowie Einsparungen durch eine effizientere Verwaltungsarbeit im Zuge der Digitalisierung sind von ebensolcher Wichtigkeit. Wir beabsichtigen, Aufgaben und Standards zu überprüfen, um Spielraum für Neuerungen zu schaffen, und zwar unter Berücksichtigung sozialer Verantwortung.
</p>
<p>
  Wir sprechen uns gegen die direkte oder indirekte Verlagerung von grundlegenden öffentlichen Dienstleistungen in den privaten Sektor zur Einnahmengenerierung aus.
</p>
<p>
  Große Infrastrukturprojekte werden im Hinblick auf Transparenz bei der Kostenentwicklung und bezüglich der Einhaltung von Budgetvorgaben besondere Aufmerksamkeit von uns erhalten.
</p>

<h2>12. Schlusswort</h2>
<p>
  Wir übernehmen Verantwortung für die ganze Stadt!
</p>
<p>
  Für dieses Programm stehen Ihre 48 Kandidat:innen der SPD-Gemeinderatsliste in großer Verbundenheit mit unserer Stadt und mit den Grundwerten der Sozialdemokratie im Herzen: Freiheit, Gerechtigkeit, Solidarität.
</p>
<p>
  Wir werben für eine starke SPD im Karlsruher Gemeinderat. Wir schaffen sozialen Ausgleich, wir gehen gemeinsame und solidarische Wege, um die Zukunft unserer Stadt zu sichern, und wir wollen Verantwortung für die ganze Stadt übernehmen.
</p>
<p>
  Dafür bitten wir Sie um alle 48 Stimmen für die SPD in Karlsruhe.
</p>
