<div class="large-page">
  <div class="card-holder">
    <ng-container *ngFor="let candidate of candidates; let i = index">
      <div class="card red-bg">
        <div class="top">
          <div class="left">
            <img src="assets/images/list/{{ candidate.image }}" alt="{{ candidate.name }}">
            <div class="candidate-info">
              <h2 [innerHTML]="candidate.name"></h2>
              <p>Listenplatz {{ candidate.placement }}</p>
            </div>
          </div>
          <div class="right">
            <p><b>Jahrgang</b>: {{ candidate.birthYear }}</p>
            <p><b>Familienstand</b>: {{ candidate.familyStatus }}</p>
            <p><b>Beruf</b>: {{ candidate.job }}</p>
            <p><b>Stadtteil</b>: {{ candidate.district }}</p>
            <div class="social_buttons">
              <a *ngIf="candidate.website_link != ''" href="{{ candidate.website_link }}"><img src="assets/images/web_white.png" rel="preload"></a>
              <a *ngIf="candidate.facebook_account != ''" href="https://facebook.com/{{ candidate.facebook_account }}"><img src="assets/images/facebook_white.png"></a>
              <a *ngIf="candidate.x_account != ''" href="https://twitter.com/{{ candidate.x_account }}"><img src="assets/images/x_white.png"></a>
              <a *ngIf="candidate.instagram_account != ''" href="https://instagram.com/{{ candidate.instagram_account }}"><img src="assets/images/instagram_white.png"></a>
            </div>
          </div>
        </div>
        <div>{{ candidate.statement }}</div>
      </div>
    </ng-container>
  </div>
</div>
