<h1>Schnitzeljagd - Lösung</h1>

<form (keyup.enter)="submitForm()" [formGroup]="SJForm" class="red-bg">
  <label for="solution">Lösungswort:</label><br>
  <input type="text" id="solution" formControlName="solution"><br>
  <span>(Groß- und Kleinschreibung ist egal.)</span>
  <br><br>
  <label for="name">Name:</label><br>
  <input type="text" id="name" formControlName="name"><br><br>
  <label for="email">E-Mail:</label><br>
  <input type="text" id="email" formControlName="email"><br><br>
  <input type="submit" value="Senden" (click)="submitForm()"><br>
  <span class="msg" *ngIf="resText != ''">{{ resText }}</span>
</form>
