<h1>Mitmachen</h1>
<p>
  Hier findet ihr einige aktuelle Projekte, die wir im laufenden Wahlkampf vorbereiten bzw. bereits umsetzen. Wir
  benötigen sehr viele helfende Hände, um diese Projekte erfolgreich durchzuführen.<br>
  Deshalb bitten wir um eure Mithilfe: Alle, die sich gerne in den Wahlkampf einbringen möchten, können die hier
  präsentierten Projektbeschreibungen durchlesen und überlegen, ob sie sich gerne an der Umsetzung eines oder mehrerer
  Projekte beteiligen möchten. Wir freuen uns auf eure Mitwirkung.<br>
  Ansprechperson für alle Interessierten ist die jeweilige Projektleitung ("Lead").
</p>
<div class="large-page">
  <div class="card-holder">
    <ng-container *ngFor="let doc of docs">
      <div class="card red-bg">
        <h2 [innerHTML]="doc.title"></h2>
        <iframe [src]="computeIFrameUrl(doc.id)"></iframe>
        <a [href]="computeDocUrl(doc.id)" target="_blank">Projektbeschreibung öffnen</a>
        <a href="mailto:{{ doc.lead }}@spdka2024.de">Projektleitung kontaktieren</a>
      </div>
    </ng-container>
  </div>
</div>

<h2>Projektideen</h2>
<p>
  Hier findet ihr Projektideen, die ihr in eurem Ortsverein eigenständig umsetzen könnt. Die Projektsheets dienen dabei als Anleitung.
</p>
<div class="large-page">
  <div class="card-holder">
    <div class="card red-bg">
      <h2>Elterncafés</h2>
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vRmQtOmdYkxJL2HbdWLVnAMvzXE1bMuN4b_J_QciZH-7FNHrOJGWWB3gqf74VF3OMVBlAvjyAHjzdBY/pub?embedded=true"></iframe>
      <a href="https://docs.google.com/document/d/e/2PACX-1vRmQtOmdYkxJL2HbdWLVnAMvzXE1bMuN4b_J_QciZH-7FNHrOJGWWB3gqf74VF3OMVBlAvjyAHjzdBY/pub" target="_blank">Projektbeschreibung öffnen</a>
      <a href="mailto:daniel@spdka2024.de">Ansprechpartner kontaktieren</a>
    </div>
    <div class="card red-bg">
      <h2>Spielplatzbegehung</h2>
      <iframe src="https://docs.google.com/document/d/e/2PACX-1vS8Uvtp8O2t7A16FxAEww-dL1uehUdPKCH5FcPJ_oSS2X0uWTQqmLXiZfTzCeT_bnxyJCZIRXQoCF06/pub?embedded=true"></iframe>
      <a href="https://docs.google.com/document/d/e/2PACX-1vS8Uvtp8O2t7A16FxAEww-dL1uehUdPKCH5FcPJ_oSS2X0uWTQqmLXiZfTzCeT_bnxyJCZIRXQoCF06/pub" target="_blank">Projektbeschreibung öffnen</a>
      <a href="mailto:daniel@spdka2024.de">Ansprechpartner kontaktieren</a>
    </div>
  </div>
</div>