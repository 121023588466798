import { Component, OnInit } from '@angular/core';

import { candidateList } from './list';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  candidates: {
    name: string,
    placement: number,
    birthYear: number,
    familyStatus: string,
    job: string,
    district: string,
    image: string,
    statement: string,
    website_link: string,
    facebook_account: string,
    x_account: string,
    instagram_account: string,
  }[] = candidateList;

  constructor() { }

  ngOnInit(): void {
  }

}
