import { Component } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: 'app-sjvideo',
  templateUrl: './sjvideo.component.html',
  styleUrl: './sjvideo.component.scss'
})
export class SJVideoComponent {
  public yt_url: SafeResourceUrl = '';
  public name: string = '';

  constructor(public activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.activatedRoute.data.subscribe({
      next: (data) => {
        this.yt_url = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + data['yt_tag']);
        this.name = data['name'];
      }
    });
  }
}
