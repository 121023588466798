import { Component } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

import { docList } from "./docs";

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrl: './jobs.component.scss'
})
export class JobsComponent {
  docs: {
    title: string,
    id: string,
    lead: string,
  }[] = docList;

  constructor(private sanitizer: DomSanitizer) {
  }

  computeIFrameUrl(id: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://docs.google.com/document/d/e/" + id + "/pub?embedded=true");
  }

  computeDocUrl(id: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://docs.google.com/document/d/e/" + id + "/pub");
  }
}
