import { Component } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Component({
  selector: 'app-sjsolution',
  templateUrl: './sjsolution.component.html',
  styleUrl: './sjsolution.component.scss'
})
export class SJSolutionComponent {
  public SJForm: FormGroup = new FormGroup({
    solution: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl(''),
  });
  public resText: string = '';

  constructor(private httpClient: HttpClient) {
  }

  public submitForm() {
    this.sendData(
      this.SJForm.value.solution,
      this.SJForm.value.name,
      this.SJForm.value.email
    ).subscribe({
      next: () => {
        this.resText = '';
      },
      error: (res) => {
        console.log(res)
        this.resText = (res.status == 200 ? res.error.text : "FEHLER: " +res.error);
      }
    });
  }

  private sendData(solution: string, name: string, email: string): Observable<any> {
    return this.httpClient.post('https://sj-api.spdka2024.de/', { solution, name, email });
  }
}
